import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from '../../../../components';
import Integrate from './img/Integrate.png';
import Grow from './img/Grow.png';
import Sell from './img/Sell.png';
import Connect from './img/Connect.png';

import css from './SectionBrand.module.css';

const SectionBrand = props => {
  return (
    <div className={css.root}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionBrand.title" />
      </H2>
      <img className={css.image} src={Integrate} />
      <p className={css.subtitle}>
        <strong>
          <FormattedMessage id="SectionBrand.subtitleBold" />
        </strong>
        <br />
        <FormattedMessage
          id="SectionBrand.subtitle"
          values={{ lineBreak: <br /> }}
        />
      </p>

      <div className={css.grid}>
        <div className={css.card}>
          <img className={css.image} src={Connect} />
          <FormattedMessage
            id="SectionBrand.connect"
            values={{ lineBreak: <br /> }}
          />
        </div>
        <div className={css.card}>
          <img className={css.image} src={Sell} />
          <FormattedMessage
            id="SectionBrand.sell"
            values={{ lineBreak: <br /> }}
          />
        </div>
        <div className={css.card}>
          <img className={css.image} src={Grow} />
          <FormattedMessage
            id="SectionBrand.grow"
            values={{ lineBreak: <br /> }}
          />
        </div>
      </div>

      <div className='row gap30'>
        <NamedLink
          className={css.ctaButton}
          name="NewBrandPage"
        >
          <FormattedMessage id="SectionBrand.ctaButton" />
        </NamedLink>

        <NamedLink
          className={css.ctaButton}
          name="SearchProductsPage"
        >
          <FormattedMessage id="SectionBrand.viewOnlineShop" />
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionBrand;
