import { userTypes } from "./userTypes"

export const getIsClient = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.userType === userTypes.client;
}

export const getIsShopsAndBrands = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.userType === userTypes.shopsAndBrands;
}

export const getUserType = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.userType;
}

export const getIsApproved = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.isApproved === true;
}