import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from '../../../../components';
import Comission from './img/Comission.png';
import FootTraffic from './img/Foot-traffic.png';
import Rotate from './img/Rotate.png';

import css from './SectionRegister.module.css';

const SectionRegister = props => {
  return (
    <div className={css.root}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionRegister.title" />
      </H2>
      <p className={css.subtitle}>
        <FormattedMessage
          id="SectionRegister.subtitle"
        />
      </p>

      <div className={css.grid}>
        <div className={css.card}>
          <img className={css.image} src={FootTraffic} />
          <FormattedMessage
            id="SectionRegister.grow"
          />
        </div>
        <div className={css.card}>
          <img className={css.image} src={Comission} />
          <FormattedMessage
            id="SectionRegister.earn"
          />
        </div>
        <div className={css.card}>
          <img className={css.image} src={Rotate} />
          <FormattedMessage
            id="SectionRegister.rotate"
          />
        </div>
      </div>
      <NamedLink
        className={css.ctaButton}
        name="NewStorePage"
      >
        <FormattedMessage id="SectionRegister.ctaButton" />
      </NamedLink>
    </div>
  );
};

export default SectionRegister;
